.Meme {
  padding-top: 42.5%; // maintains 40:17 aspect ratio
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  cursor: pointer;
}

.NewMeme {
  img {
    width: 100%;
  }
}

.Meme:hover {
  opacity: 0.8;
}

.screenshotWrapper {
  position: relative;
}

.screenshotImage {
  width: 1280px;
}

.screenshotText {
  position: absolute;
  bottom: 0;
  left: 640px;
  transform: translate(-50%, 0);
  font-size: 32px;
  color: yellow;
  line-height: 0.5; //unitless makes it a percentage of font size
  white-space: nowrap;
}

.centered {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 4vw;
  text-shadow: 1px 1px 1px black;
  color: yellow;
  // background-color: rgba(255, 0, 0, 0.123); // for debugging
  line-height: 0.5; //unitless makes it a percentage of font size
  white-space: nowrap;
  @media only screen and (min-width: 37.5em) {
    font-size: 1rem;
  }
}

.grid {
  @media only screen and (min-width: 37.5em) {
    grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  }

  display: grid;
  grid-gap: 1rem;
  // padding-top: 5rem;
}

.memeModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.memeModal {
  position: relative;
  padding: 10rem 1rem 1rem 1rem;
  width: 100%;
  background-color: white;
  margin: 1rem;
}

.memeImage {
  width: 100%;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  // border-radius: 1rem;
  padding-top: 42.5%;
}
