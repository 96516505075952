.modalWrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s;
  opacity: 0;
}

.modal {
  padding: 0rem 2rem;
  background: rgb(15, 16, 17);
  transform: translate(0,-2rem);  
  transition: opacity 0.3s;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
  width: 100%;
  
  @media only screen and (min-width: 37.5em) {
    max-width: 50rem;
    width: 80%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  }
}

.modalVisible {
  opacity: 1;
}

.modalContentVisible {
  transform: translate(0, 0rem);
}