.asyncWrapper {
  position: relative;
  background-color: #242424;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  padding-top: 42.5%;
}

.remote {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 2s;
}

.pulse {
  animation: pulse 0.7s infinite ease-in-out;
}

@keyframes pulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}
