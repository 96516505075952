.home {
    display: flex;
    align-items: center;
    flex-direction: column;
    .randomQuote {
      color: white;
      padding: 0 1rem;
    }

    @media only screen and (min-width: 37.5em) {
        .randomQuote {
            font-size: 1.5rem;
        }
      }
  }