.menu {
  position: absolute;
  margin-left: -2.5rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 2rem;
  @media only screen and (min-width: 37.5em) {
    margin-left: -3.5rem;
    font-size: 3rem;
  }

  a {
    color: inherit;
  }
  
}

.searchmenuContainer {
  position: sticky;
  display:flex;
  z-index: 3;
  margin: 1rem;
  align-items: center;
  
  top: 1rem;
  @media only screen and (min-width: 37.5em) {
    margin: 1rem 2rem;
  }
  padding-left: 1rem;
}

.searchContainer {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  border-radius: .375rem;
  // background-color: #23272A;
  // background-color: rgb(5, 5, 5);
  background-color: white;
  
  width: 100%;

  input {
    color: black;
    width: 100%;
    font-size: 1rem;
    border: none;
    outline: none;
    padding: 1rem;
    border-radius: .375rem;
    // background-color: #23272A;
    // background-color: rgb(5, 5, 5);
    background-color: white;

    // border: solid 1px #23272A;
    // border: solid 1px rgb(5, 5, 5);
    transition: 0.15s;
    -webkit-appearance: none;
  
    // &:focus {
    //   border: solid 1px white;
    // }
  }

  @media only screen and (min-width: 37.5em) {
      // margin: 1rem 2rem;
    
      input {
        font-size: 2rem;
        padding: 1.5rem;
      }
    }
}


