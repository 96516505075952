.credits {
  text-align: center;
  color: rgb(161, 161, 161);
  font-size: 0.8rem;
  position: fixed;
  bottom: 2rem;
  a {
    color: inherit;
    text-decoration: none;
  }
}