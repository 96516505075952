body {
  // background-color: #2C2F33;
  background-color: #141414;
}

.logo {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 100%;
  color: white;
  position: relative;
  

  
  img {
    -webkit-user-drag: none;
    user-select: none;
    transition: height 0.15s;
    height: 3rem;
    
    @media only screen and (min-width: 37.5em) {
      height: 4rem;
    }
  }
}

.gradientHeader {
  width: 100%;
  height: 10rem;
  background: 
    linear-gradient(to bottom, transparent, #141414),
    linear-gradient(160deg, #453AA4, transparent 60%);
  position: sticky;
  margin-top: -10rem;
  z-index: -999;
  top: -2.5rem;
  pointer-events: none;
}

.gradientHeaderPreq {
  width: 100%;
  height: 10rem;
  background: 
    linear-gradient(to bottom, transparent, #141414),
    linear-gradient(160deg, #FFE81F, transparent 60%);
  // position: sticky;
  position: fixed;
  // margin-top: -10rem;
  margin-top: -3rem;
  top:0;
  z-index: -999;
  // top: -2.5rem;
  pointer-events: none;
}

.body {
  padding: 2rem 1rem;
}

.prequelBody {
  padding-bottom: 2rem;
}

@media only screen and (min-width: 37.5em) {
  .body {
    padding: 3rem;
  }

  .prequelBody {
    padding: 3rem;
  }
}

.loadMore {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.alert {
  position: fixed;
  box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2);
  background: cornflowerblue;
  padding: 1rem 0;
  width: 250px;
  height: 20px;
  border-radius: .375rem;
  color: white;
  font-weight: 500;
  top: 7rem;
  left: 50%;
  margin-left: -125px;
  text-align: center;
  line-height: 20px;
  transition: 0.3s;
  z-index: 999;
}

.alert-hide{
  visibility: hidden;
  opacity: 0;
}

.alert-show {
  visibility: visible;
  opacity: 1;
  top: 8rem;
}